<template>
	<div class="shell-header-top" :class="{ 'small-header-top': $breakpoints.smAndDown }">

		<div v-if="!$breakpoints.smAndDown" ref="headerImage" :class="`shell-header is-${bannerSize}`">
			<div v-if="course.theme_header" class="header-image" :style="`background-image:url('${course.theme_header}')`"></div>
		</div>

		<div ref="headerBar" class="shell-header-bar primary-bg">			
			<div class="course-logo">
				<a v-if="course && course.homepage_link && course.theme_logo" :href="course.homepage_link" class="menubar-icon" title="Return to Main Site">					
					<img v-if="course.theme_logo" :src="course.theme_logo"/>
				</a>
				<img v-else-if="course.theme_logo" :src="course.theme_logo"/>
			</div>

			<div class="header-bar-content">
				<div class="current-date-switcher">
					<button type="button" class="month-nav-arrow" aria-label="Previous Month" @click.prevent="changeCurrentDay(-1)"><i class="fas fa-chevron-left"></i></button>
					<div class="current-date">{{ currentDate | dateFormat($breakpoints.smAndDown ? 'ddd MM/DD' : 'dddd MMMM D, YYYY') }}</div>
					<button type="button" class="month-nav-arrow" aria-label="Next Month" @click.prevent="changeCurrentDay(1)"><i class="fas fa-chevron-right"></i></button>
				</div>

				<ul class="upcoming-dates">
					<li v-for="date in nextFewDays" :key="date.date">
						<router-link class="upcoming-date-option" :to="date.link">{{ date.text }}</router-link>
					</li>
				</ul>

				<div v-if="!courseParams.hide_weather_status && statusOptions[header.weather]" class="current-conditions">
					<div class="conditions-label">Conditions: {{ header.weather | titleCase }}</div>
					<div class="conditions-icon"><i :class="statusOptions[header.weather].icon"/></div>
				</div>

				<div class="choose-date">
					<button class="choose-date-btn primary-btn-inv" @click.prevent="datePicker = true">
						<span class="btn-label">Choose Date</span>
						<span class="btn-icon"><i class="fas fa-calendar-alt"/></span>
					</button>
				</div>
			</div>
		</div>

		<template v-if="header.use_widget_html">
			<div class="header-notes-and-weather">
				<div class="widget-section">
					<div v-html="header.widget_html"></div>
				</div>
				<div class="notes-section">
					<div v-if="header.notes.trim() != ''" class="course-notes" v-html="header.notes">
					</div>
				</div>
			</div>
		</template>

		<template v-else>
			<div v-if="header.notes.trim() != ''" class="course-notes" v-html="header.notes">
			</div>
		</template>

		<date-picker-popup :show.sync="datePicker" />
	</div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash/function';
import { mapState, mapMutations } from 'vuex';

import filters from 'igolf/shared/filters';

import DatePickerPopup from './DatePickerPopup.vue';

export default {
	mixins: [filters],
	components: { DatePickerPopup },

	props: {
		bannerSize: { type: String, default: 'small' },
	},

	data() 
	{
		return {
			datePicker: false,
			statusOptions: {
				'fair': 				{ status: 'fair', icon: 'fas fa-sun' },
				'light rain': 			{ status: 'light rain', icon: 'fas fa-cloud-rain' },
				'lightning': 			{ status: 'lightning', icon: 'fas fa-bolt' },
				'mostly cloudy': 		{ status: 'mostly cloudy', icon: 'fas fa-cloud-sun' },
				'overcast': 			{ status: 'overcast'	, icon: 'fas fa-cloud' },
				'partly cloudy': 		{ status: 'partly cloudy', icon: 'fas fa-cloud-sun' },
				'raining': 				{ status: 'raining', icon: 'fas fa-cloud-showers-heavy' },
				'scattered showers': 	{ status: 'scattered showers', icon: 'fas fa-cloud-sun-rain' },
				'snow': 				{ status: 'snow', icon: 'fas fa-snowflake' },
				'sunny': 				{ status: 'sunny', icon: 'fas fa-sun' },
			}
		}
	},

	computed: {
		...mapState({
			courseParams: state => state.teesheet.courseParams,
			currentDate: state => state.teesheet.date,
			course: state => state.teesheet.course,
			header: state => state.teesheet.header,
		}),

		nextFewDays()
		{
			var dates = [];
			var current = moment(this.currentDate, 'YYYY-MM-DD');

			for(var i=0; i<3; i++)
			{
				let dateStr = current.add(1, 'days').format('YYYY-MM-DD');
				dates.push({ 
					date: dateStr, 
					text: current.format('ddd, MMM DD'),
					link: `/teesheet/${this.course.url_slug}/${dateStr}`,
				});
			}

			return dates;
		}
	},

	methods: {
		//...mapMutations('teesheet', ['changeCurrentDay']),

		changeCurrentDay(dir)
		{
			var date = moment(this.currentDate, 'YYYY-MM-DD').add(dir, 'days').format('YYYY-MM-DD');
			this.$router.push(`/teesheet/${this.course.url_slug}/${date}`);
		},

		onScroll()
		{
			if (this.$refs.headerImage)
			{
				if (document.documentElement.scrollTop >= this.$refs.headerImage.clientHeight - 95)
					this.$refs.headerBar.classList.add('is-fixed');
				else
					this.$refs.headerBar.classList.remove('is-fixed');
			}
		}
	},

	mounted()
	{
		this.onScrollHandler = debounce(this.onScroll.bind(this), 10);
		window.addEventListener('scroll', this.onScrollHandler);

		this.onScroll();
	},

	beforeDestroy()
	{
		window.removeEventListener('scroll', this.onScrollHandler);
	}
}
</script>